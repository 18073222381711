import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Topbar from './Topbar'
import Bottombar from './Bottombar'
import Content from './Content'
import ConfirmationAlert from './ConfirmationAlert'
import SnackbarWrapper from './SnackbarWrapper'
import { client } from '../config'
import ContentTypes from '../ContentType'

const fetch = require('node-fetch')
const FormData = require('form-data')

const conf = client

const styles = {
  contentGridStyle: {
    width: '100%',
    flex: '0 1 auto',
    marginTop: '64px',
    marginBottom: '15px',
    overflow: 'auto'
  },
  errorStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#d68d8d',
    padding: '25px',
    borderRadius: '5px'
  },
  gridTopBarStyle: {
    width: '100%',
    flex: '0 1 83px'
  },
  gridBottomBarStyle: {
    width: '100%',
    flex: '0 1 65px'
  },
  workspaceGridStyle: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    backgroundColor: '#ebebeb'
  }
}

const infoPageTemplate = {
  title: null,
  synopsis: null,
  image: null,
  id: null,
  titlePublishDate: null,
  synopsisPublishDate: null,
  imagePublishDate: null
}

class Workspace extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedRegionPage: 0,
      showConfirm: false,
      showError: false,
      showPublish: false,
      errorText: '',
      localImages: [],
      initial: {},
      info: {}
    }

    this.handleSelectedChanged = this.handleSelectedChanged.bind(this)
    this.handleContentChanged = this.handleContentChanged.bind(this)
    this.handleImageChanged = this.handleImageChanged.bind(this)
    this.handlePostState = this.handlePostState.bind(this)
    this.handleAddInfoPage = this.handleAddInfoPage.bind(this)
    this.handleRemoveInfoPage = this.handleRemoveInfoPage.bind(this)
    this.handleInitiateRemoveInfoPage = this.handleInitiateRemoveInfoPage.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleSnackbarHide = this.handleSnackbarHide.bind(this)
    this.copyJSON = this.copyJSON.bind(this)

    const date = new Date()
    this.year = date.getFullYear()
  }

  // copies the supplied JSON object so there is no reference lingering
  copyJSON (json) {
    return JSON.parse(JSON.stringify(json))
  }

  componentDidMount () {
    const { token } = this.props.context
    const { mode } = this.props

    if (mode === 'info') {
      fetch(`${conf.api.address}/info`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then((res) => {
          if (res.status === '200') {
            // console.log('got info result', typeof res.data, res.data)
            const data = typeof res.data === 'string' ? JSON.parse(res.data) : res.data
            const localImages = []
            for (const key in data.categories[0].items) {
              localImages[key] = false
            }
            this.setState({ localImages, initial: this.copyJSON(data), info: data })
          } else if (res.status === '400') {
            console.log(res.error)
            this.setState({ showError: true, errorText: res.error })
          } else {
            console.log(res.error)
            this.props.onLogout()
          }
        },
        (error) => {
          console.log(error)
        })
    } else {
      fetch(`${conf.api.address}/message`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then((res) => {
          if (res.status === '200') {
            // console.log('got message result', typeof res.data, res.data)
            const data = typeof res.data === 'string' ? JSON.parse(res.data) : res.data
            this.setState({ initial: this.copyJSON(data), info: data })
          } else if (res.status === '400') {
            console.log('status 400')
            console.log(res.error)
            this.setState({ showError: true, errorText: res.error })
          } else {
            console.log(res.error)
            this.props.onLogout()
          }
        })
    }
  }

  handlePostState () {
    const { token } = this.props.context
    const { mode } = this.props

    this.setState({ showPublish: true })

    if (mode === 'info') {
      fetch(`${conf.api.address}/info/update`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          region: this.state.info.region,
          categories: this.state.info.categories
        })
      })
        .then(res => res.json())
        .then((res) => {
          if (res.status !== '200') {
            console.log(res.error)
            this.props.onLogout()
          } else {
            const localImages = this.state.localImages
            for (const key in localImages) {
              localImages[key] = false
            }

            this.setState({ localImages })
            this.componentDidMount()
          }
        })
    } else {
      fetch(`${conf.api.address}/message/update`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          region: this.state.info.region,
          categories: this.state.info.categories
        })
      })
        .then(res => res.json())
        .then((res) => {
          if (res.status !== '200') {
            console.log(res.error)
            this.props.onLogout()
          }
        })
    }
  }

  handleSelectedChanged (index) {
    this.setState({ selectedRegionPage: index })
  }

  handleContentChanged (type, data) {
    const selected = this.state.selectedRegionPage
    const items = this.state.info.categories[0].items
    let infoPage

    switch (type) {
      case ContentTypes.TITLE:
        infoPage = {
          ...items[selected],
          title: data,
          titlePublishDate: Date.now().toString()
        }
        break
      case ContentTypes.SYNOPSIS:
        infoPage = {
          ...items[selected],
          synopsis: data.toString().replace(/"/g, '&quot;').replace(/(\r\n|\n|\r)/gm, ''),
          synopsisPublishDate: Date.now().toString()
        }
        break
      // no default
    }

    items[selected] = infoPage
    this.setState({ items })
  }

  handleImageChanged (files) {
    const selected = this.state.selectedRegionPage
    const items = this.state.info.categories[0].items
    const formData = new FormData()
    const { token } = this.props.context

    files = Array.from(files)
    console.log('files', files)
    if (files[0]) {
      formData.append('image', files[0])
    }

    console.log('sending image to:', `${conf.api.address}/upload/${selected}`)
    fetch(`${conf.api.address}/upload/${selected}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then((res) => {
        if (res.status === '200') {
          const infoPage = {
            ...items[selected],
            image: `${res.imageUrl}`,
            imagePublishDate: Date.now().toString()
          }
          console.log('infoPage', infoPage)
          items[selected] = infoPage

          const localImages = this.state.localImages
          localImages[selected] = true

          this.setState({ localImages, items })
        } else if (res.status === '401') {
          console.log(res.error)
          this.props.onLogout()
        } else {
          console.log(res.error)
          this.setState({ errorText: res.error })
        }
      })
  }

  handleAddInfoPage () {
    const items = this.state.info.categories[0].items
    const count = this.state.info.categories[0].items.length
    const id = count + 1
    let infoPage = infoPageTemplate

    infoPage = {
      title: 'New info page title',
      synopsis: 'New info page synopsis',
      image: '',
      id,
      titlePublishDate: Date.now().toString(),
      synopsisPublishDate: Date.now().toString(),
      imagePublishDate: Date.now().toString()
    }

    items[count] = infoPage
    this.setState({ items })
    this.setState({ selectedRegionPage: count })
  }

  handleInitiateRemoveInfoPage () {
    this.setState({ showConfirm: true })
  }

  handleRemoveInfoPage () {
    const items = this.state.info.categories[0].items
    const selected = this.state.selectedRegionPage

    items.splice(selected, 1)
    this.setState({ items })
    this.setState({ selectedRegionPage: 0 })
    this.setState({ showConfirm: false })
  }

  handleCancel () {
    this.setState({ showConfirm: false })
  }

  handleSnackbarHide () {
    this.setState({ showPublish: false })
  }

  render () {
    const { classes } = this.props
    const { selectedRegionPage, info, initial } = this.state
    const publishMessage = 'Data publicerat!'

    return (
      <div style={{ height: '100%' }}>
        {
          this.state.showPublish &&
            <>
              <SnackbarWrapper
                message={publishMessage}
                onSnackbarHide={this.handleSnackbarHide}
              />
              {/* <SnackbarContent
                message={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircle />&nbsp;{publishMessage}
                  </span>
                }
                role='alert'
                onClick={() => this.setState({ showPublish: false })}
              /> */}
            </>
        }
        {
          this.state.showConfirm &&
            <ConfirmationAlert
              info={info}
              selected={selectedRegionPage}
              onRemoveInfoPage={this.handleRemoveInfoPage}
              onCancel={this.handleCancel}
            />
        }
        <Grid container spacing={0} direction='row' className={classes.workspaceGridStyle}>
          <Grid item m={0} p={0} xs={12} className={classes.gridTopBarStyle} style={{ height: '83px' }}>
            <Topbar text='Publiceringsverktyg' onLogout={this.props.onLogout} />
          </Grid>
          <Grid item xs={12} className={classes.contentGridStyle}>
            {
              this.state.showError ? (
                <div className={classes.errorStyle}>{this.state.errorText}</div>
              ) : (
                <Content
                  selected={selectedRegionPage}
                  initial={initial}
                  info={info}
                  context={this.props.context}
                  mode={this.props.mode}
                  localImages={this.state.localImages}
                  onModeChanged={this.props.onModeChanged}
                  onSelectedChanged={this.handleSelectedChanged}
                  onContentChanged={this.handleContentChanged}
                  onImageChanged={this.handleImageChanged}
                  onPostState={this.handlePostState}
                  onAddInfoPage={this.handleAddInfoPage}
                  onRemoveInfoPage={this.handleInitiateRemoveInfoPage}
                  imageServerBaseUrl={conf.api.address}
                  errorMsg={this.state.errorText}
                />
              )
            }
          </Grid>
          <Grid item xs={12} className={classes.gridBottomBarStyle}>
            <Bottombar text={'Copyright - Kalejdo Bredband AB ' + this.year} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Workspace)
