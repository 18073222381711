import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItem,
  Divider,
  Typography,
  List,
  Toolbar,
  AppBar,
  CssBaseline,
  Drawer
} from '@material-ui/core'
import {
  Announcement,
  ChevronLeft,
  ChevronRight,
  Menu,
  Home,
  /* Headset,
  Build, */
  Reorder,
  AddBox
} from '@material-ui/icons'

const drawerWidth = '240px'
const drawerMaxHeight = '75%'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  dividerStyle: {
    backgroundColor: 'rgba(0,0,0, 0.3)'
  },
  appBar: {
    top: '83px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: '83px',
    marginBottom: '65px',
    borderRight: '1px solid rgba(0,0,0, 0.3)',
    overflow: 'scroll',
    maxHeight: drawerMaxHeight
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(0,0,0, 0.3)'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    paddingBottom: 0
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  toolBar: {
    backgroundColor: '#69ba41',
    boxShadow: 0
  },
  drawerBottomBit: {
    backgroundColor: '#303033',
    height: '65px',
    width: drawerWidth,
    display: 'inline-block',
    position: 'fixed',
    bottom: 0,
    left: 0
  },
  indentedList: {
    marginLeft: '30px'
  },
  drawerContentStyle: {
    width: '100%',
    height: '100%'
  }
}))

export default function PersistentDrawerLeft (props) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const { selected, info, initial, mode } = props
  const items = info.categories[0].items

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleMarked = (value, index) => {
    if (initial.categories[0].items[index]) {
      const title = value.title
      const initialTitle = initial.categories[0].items[index].title
      const synopsis = value.synopsis
      const initialSynopsis = initial.categories[0].items[index].synopsis

      if (title !== initialTitle || synopsis !== initialSynopsis) {
        return { fontWeight: 'bold' }
      } else {
        return { fontWeight: 'normal' }
      }
    } else {
      return { fontWeight: 'bold' }
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar className={classes.toolBar}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu />
          </IconButton>
          <Typography variant='h6' noWrap>
            {items[selected] ? items[selected].title : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider className={classes.dividerStyle} />
        <List>
          {info.categories.map((currentValue) => (
            <ListItem
              button
              key={currentValue.name}
              onClick={() => window.location.reload()}
            >
              <ListItemIcon><Home /></ListItemIcon>
              <ListItemText primary={currentValue.name} />
            </ListItem>
          ))}
          <List>
            {items.map((currentValue, index) => (
              <ListItem
                button
                key={currentValue.title}
                onClick={() => props.onSelectedChanged(index)}
                selected={selected === index}
              >
                <ListItemText
                  inset
                  disableTypography
                  primary={currentValue.title}
                  style={handleMarked(currentValue, index)}
                />
              </ListItem>
            ))}
          </List>
        </List>
        <Divider className={classes.dividerStyle} />
        <List>
          <ListItem
            button
            key={(mode === 'info') ? 'Lägg till menyrad' : 'Lägg till anslag'}
            onClick={props.onAddInfoPage}
          >
            <ListItemIcon><AddBox /></ListItemIcon>
            <ListItemText
              primary={(mode === 'info') ? 'Lägg till menyrad' : 'Lägg till anslag'}
            />
          </ListItem>
        </List>
        <Divider className={classes.dividerStyle} />
        <List>
          <ListItem
            button
            key={(mode === 'info') ? 'Anslagstavlan' : 'Informationsmeny'}
            onClick={props.onModeChanged}
          >
            <ListItemIcon>
              {(mode === 'info') ? <Announcement /> : <Reorder />}
            </ListItemIcon>
            <ListItemText primary={(mode === 'info') ? 'Anslagstavlan' : 'Informationsmeny'} />
          </ListItem>
          <Divider className={classes.dividerStyle} />
          {/* {['Administration', 'Support'].map((text, index) => (
            <ListItem
              button
              disabled
              key={text}
            >
              <ListItemIcon>{index % 2 === 0 ? <Build /> : <Headset />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))} */}
        </List>
        <div className={classes.drawerBottomBit} />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        {/* <div className={classes.drawerHeader} /> */}
        <div className={classes.drawerContentStyle}>
          {props.children}
        </div>
      </main>
    </div>
  )
}
